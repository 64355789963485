import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/app/docs/node_modules/@uchiru/gatsby-theme-doctocat/src/components/layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Our goal with Uchiru Components is to hit the sweet spot between providing too little and too much styling flexibility; too little and the design system is too rigid, and too much and it becomes too difficult to maintain a consistent style. Our components already support a standard set of `}<a parentName="p" {...{
        "href": "/about/system-props"
      }}>{`system props`}</a>{`, but sometimes a component just isn't `}<em parentName="p">{`quite`}</em>{` flexible enough to look the way you need it to look. For those cases, we provide the `}<inlineCode parentName="p">{`sx`}</inlineCode>{` prop.`}</p>
    <p>{`The `}<inlineCode parentName="p">{`sx`}</inlineCode>{` prop allows ad-hoc styling that is still theme aware. Declare the styles you want to apply in camel-cased object notation, and try to use theme values in appropriate CSS properties when possible. If you've passed a custom theme using `}<inlineCode parentName="p">{`ThemeProvider`}</inlineCode>{` or a `}<inlineCode parentName="p">{`theme`}</inlineCode>{` prop, the `}<inlineCode parentName="p">{`sx`}</inlineCode>{` prop will honor the custom theme. For more information on theming in Uchiru Components, check out `}<a parentName="p" {...{
        "href": "/about/uchiru-theme"
      }}>{`the Uchiru Theme documentation`}</a>{`.`}</p>
    <h2>{`When to use the `}<inlineCode parentName="h2">{`sx`}</inlineCode>{` prop`}</h2>
    <p>{`The `}<inlineCode parentName="p">{`sx`}</inlineCode>{` prop provides a lot of power, which means it is an easy tool to abuse. To best make use of it, we recommend following these guidelines:`}</p>
    <ul>
      <li parentName="ul">{`Use the `}<inlineCode parentName="li">{`sx`}</inlineCode>{` prop for small stylistic changes to components. For more substantial changes, consider abstracting your style changes into your own wrapper component.`}</li>
      <li parentName="ul">{`Use `}<a parentName="li" {...{
          "href": "/about/system-props"
        }}>{`system props`}</a>{` instead of the `}<inlineCode parentName="li">{`sx`}</inlineCode>{` prop whenever possible.`}</li>
      <li parentName="ul">{`Avoid nesting and pseudo-selectors in `}<inlineCode parentName="li">{`sx`}</inlineCode>{` prop values when possible.`}</li>
    </ul>
    <h2>{`Basic example`}</h2>
    <p>{`This example demonstrates applying a bottom border to `}<inlineCode parentName="p">{`Heading`}</inlineCode>{`, a component that does not receive `}<inlineCode parentName="p">{`BORDER`}</inlineCode>{` system props. The `}<inlineCode parentName="p">{`borderBottomWidth`}</inlineCode>{` value comes from `}<inlineCode parentName="p">{`theme.borderWidths`}</inlineCode>{` and `}<inlineCode parentName="p">{`borderBottomColor`}</inlineCode>{` comes from `}<inlineCode parentName="p">{`theme.colors`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`<Heading pb={2}>Heading</Heading>

<Heading
  pb={2}
  sx={{
    borderBottomWidth: 1,
    borderBottomColor: 'border.gray',
    borderBottomStyle: 'solid'
  }}>
    Heading with bottom border
</Heading>
`}</code></pre>
    <h2>{`Responsive values`}</h2>
    <p>{`Just like `}<a parentName="p" {...{
        "href": "https://styled-system.com/responsive-styles"
      }}>{`values passed to system props`}</a>{`, values in the `}<inlineCode parentName="p">{`sx`}</inlineCode>{` prop can be provided as arrays to provide responsive styling.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`<BorderBox
  p={2}
  sx={{
    bg: ['red.1', 'green.1', 'blue.1', 'turquoise.1', 'yellow.1']
  }}>
    Responsive background color
</BorderBox>
`}</code></pre>
    <h2>{`Nesting, pseudo-classes, and pseudo-elements`}</h2>
    <p>{`The `}<inlineCode parentName="p">{`sx`}</inlineCode>{` prop also allows for declaring styles based on media queries, psueudo-classes, and pseudo-elements. This example, though contrived, demonstrates the ability:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`<Box sx={{
  '> *': {
    borderWidth: 1,
    borderColor: 'border.gray',
    borderStyle: 'solid',
    borderBottomWidth: 0,
    padding: 2,
    ':last-child': {
      borderBottomWidth: 1
    },
    ':hover': {
      bg: 'gray.1'
    }
  }
}}>
  <Box>First</Box>
  <Box>Second</Box>
  <Box>Third</Box>
</Box>
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      